import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { API_KEY, API_SECRET, BASEURL } from '../pages/Constants';
import { getAccessToken } from '../services/http-common';
import toast from 'react-hot-toast';

interface UpdateOptionImageInterface {
  mediaId: any;
  onCancel: any;
  optionId: any;
}
const UpdateOptionMediaImage = ({
  mediaId,
  onCancel,
  optionId,
}: UpdateOptionImageInterface) => {
  const [errors, setErrors] = useState<Record<string, string>>({}); // Record<string, string> defines an object with string keys and string values
  const [file, setFile] = useState<File | null>(null);
  const accessToken = getAccessToken();
  const [altTag, setAltTag] = useState('');
  
    const handleAltChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const tag = e.target.value;
      if (tag) {
        setAltTag(tag);
      }
    };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };
  const schema = Yup.object().shape({
    file: Yup.mixed().required('Image is required'),
        altTag: Yup.string().required('Please provide alt tag'),
    
  });

  const [isUpdating, setIsUpdating] = useState(false);

  const onSubmit = async () => {
    try {
      await schema.validate({ file, altTag }, { abortEarly: false });
      setIsUpdating(true);
      const formData = new FormData();
      if (file && altTag) {
        formData.append('file', file);
        formData.append('alt', altTag);
      }
      const response = await axios.post(
        `${BASEURL}option/media/update/${mediaId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if(response.data && response.status=== 200){
        setIsUpdating(false);
        toast.success("Image updated successfully");
        window.location.href = `/options/media/${optionId}`;
      }
      else{
        setIsUpdating(false);
        toast.error("somwthing went wrong while updating image");
      }  
    } catch (error) {
      setIsUpdating(false);
      if (error instanceof Yup.ValidationError) {
        // Validation failed, set error messages
        let newErrors: Record<string, string> = {};
        error.inner.forEach((e) => {
          if (e.path) {
            newErrors[e.path] = e.message;
          }
        });
        setErrors(newErrors);
      } else {
        toast.error("somwthing went wrong while updating image");
        console.error('Error submitting form:', error);
        // Handle other types of errors
      }
    }
  };

  return (
    <div className="fixed h-screen  z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg">
        <div className="flex justify-center mb-4">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission behavior
            }}
          >
            <div className="p-6.5">
              <div className=" flex flex-col gap-6 xl:flex-row">
                <div className="w-full">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Media Image <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    placeholder="select Image"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10"
                  />
                  {errors.file && (
                    <span className="text-sm text-danger">{errors.file}</span>
                  )}
                </div>
              </div>

              <div className=" flex flex-col gap-6 xl:flex-row mt-5">
                <div className="w-full">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Alt tag<span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="text"
                    name="altTag"
                    onChange={handleAltChange}
                    placeholder="image alt tag"
                    className="w-full text-black rounded border-[1.5px] border-bodydark bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary active:border-[2px] focus:border-[2px] focus:shadow-2 disabled:cursor-default disabled:bg-whiter dark:bg-form-input"
                  />
                  {errors.altTag && (
                    <span className="text-sm text-danger">{errors.altTag}</span>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="flex space-x-8 item-center justify-center ">
          <button
            type="submit"
            className="text-white  focus:ring-4 bg-success hover:bg-green-700 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`text-white bg-primary hover:bg-indigo-800 focus:ring-4  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center ${isUpdating && 'cursor-not-allowed'}`}
            disabled={isUpdating}
            onClick={onSubmit}
          >
            {isUpdating ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="20"
                viewBox="0 0 100 100"
                fill="none"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="35"
                  stroke="#4A90E2"
                  stroke-width="8"
                  stroke-dasharray="164.93361431346415 56.97787143782138"
                  stroke-linecap="round"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  />
                </circle>
              </svg>
            ) : (
              'Update'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateOptionMediaImage;
