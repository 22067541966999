import Breadcrumb from '../../components/Breadcrumb';
import CategoryInterface from './CategoryInterface';
import CategoryService from '../../services/CategoryService';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Params, Link } from 'react-router-dom';
import UpdateCategoryImage from '../../components/UpdateCategoryImage';
import AddCategoryImage from '../../components/AddCategoryImage';

const CategoryDetails: React.FC<{}> = () => {
  const { categoryId } = useParams<Params>();
  const [updateImage, setUpdateImage] = useState(false);
  const [AddImage, setAddImage] = useState(false);

  const [category, setCategory] = React.useState<CategoryInterface>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (categoryId != undefined) {
          const response: AxiosResponse<CategoryInterface> =
            await CategoryService.getCategory(categoryId);
          const responseData: CategoryInterface = response.data;
          setCategory(responseData);
        } else {
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Breadcrumb pageName="Category" />

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
        <div className="flex flex-col gap-9">
          {/* <!-- Input Fields --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-2.5 px-6.5 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Category Details
                <span className="px-1">
                  <button className="text-primary" title="Edit Client">
                    <Link
                      className="hover:text-primary"
                      to={`/category/update/${categoryId}`}
                    >
                      <svg
                        className="feather feather-edit text-blue-600 "
                        fill="none"
                        height="30"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                      </svg>
                    </Link>
                  </button>
                </span>
              </h3>
            </div>
            <div className="flex flex-col gap-5 p-6.5">
              {' '}
              <div className="">
                <label className="mb-2.5 block text-black dark:text-white">
                  Category Name
                </label>
                <input
                  type="text"
                  name="categoryName"
                  value={category?.categoryName}
                  placeholder="Enter Category Name"
                  className="w-full rounded border-[1.5px] border-primary bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="">
                <label className="mb-2.5 block text-black dark:text-white">
                  Slug
                </label>
                <input
                  type="text"
                  name="slug"
                  value={category?.slug}
                  placeholder="Enter Category slug"
                  className="w-full rounded border-[1.5px] border-primary bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="">
                <label className="mb-2.5 block text-black dark:text-white">
                  Priority
                </label>
                <input
                  type="text"
                  name="priority"
                  value={category?.priority}
                  placeholder="Enter priority"
                  className="w-full rounded border-[1.5px] border-primary bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="">
                <label className=" block text-black dark:text-white">
                  Category Image
                </label>
                {category?.categoryImageUrl ? (
                  <div className="  ">
                    <div className="mt-2 border border-primary rounded flex h-60  w-full justify-center">
                      <img src={category?.categoryImageUrl}  alt= { category.categoryImageAlt? category.categoryImageAlt : category.categoryName} className="" />
                    </div>
                    <div className="flex mt-3  items-center justify-center w-auto h-8  cursor-pointer gap-2 rounded bg-primary  text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4">
                      <button
                        type="submit"
                        onClick={() => setUpdateImage(true)}
                        className="flex space-x-1"
                      >
                        <svg
                          className="fill-current mt-0.5"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM2.625 4.66663H4.75C4.94439 4.66663 5.12614 4.76353 5.23464 4.9258L6.22814 6.41663H10.7719L11.7654 4.9258C11.8739 4.76353 12.0556 4.66663 12.25 4.66663H13.0833C13.2487 4.66663 13.4066 4.72875 13.5192 4.83748C13.6318 4.94621 13.6903 5.09291 13.6833 5.2433L12.8276 11.0037C12.7555 11.4467 12.3443 11.75 11.8958 11.75H2.10416C1.65568 11.75 1.24446 11.4467 1.17239 11.0037L0.316686 5.2433C0.309722 5.09291 0.368198 4.94621 0.480796 4.83748C0.593395 4.72875 0.75128 4.66663 0.916664 4.66663H2.625ZM11.0833 12.8333H2.91667V11.9166H11.0833V12.8333Z"
                            fill="#ffffff"
                          />
                        </svg>
                        <span>Update Image</span>
                      </button>
                      {updateImage && (
                <UpdateCategoryImage
                  onCancel={() => setUpdateImage(false)}
                  categoryId={category.categoryId}
                />
              )}
                    </div>
                  </div>
                ) : (
                  <div className="flex  h-8 items-center  justify-center mt-2 cursor-pointer gap-2 rounded bg-primary py-1.5 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4">
                    <button
                      type="submit"
                      onClick={() => setAddImage(true)}
                      className="flex space-x-1"
                    >
                      <svg
                        className="fill-current mt-0.5"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM2.625 4.66663H4.75C4.94439 4.66663 5.12614 4.76353 5.23464 4.9258L6.22814 6.41663H10.7719L11.7654 4.9258C11.8739 4.76353 12.0556 4.66663 12.25 4.66663H13.0833C13.2487 4.66663 13.4066 4.72875 13.5192 4.83748C13.6318 4.94621 13.6903 5.09291 13.6833 5.2433L12.8276 11.0037C12.7555 11.4467 12.3443 11.75 11.8958 11.75H2.10416C1.65568 11.75 1.24446 11.4467 1.17239 11.0037L0.316686 5.2433C0.309722 5.09291 0.368198 4.94621 0.480796 4.83748C0.593395 4.72875 0.75128 4.66663 0.916664 4.66663H2.625ZM11.0833 12.8333H2.91667V11.9166H11.0833V12.8333Z"
                          fill="#ffffff"
                        />
                      </svg>
                      <span>Add Image</span>
                    </button>
                    {AddImage && (
                                <AddCategoryImage
                                  onCancel={() => setAddImage(false)}
                                  categoryId={category?.categoryId}
                                />
                              )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-9">
          {/* <!-- Textarea Fields --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                SEO Details
              </h3>
            </div>
            <div className="flex flex-col gap-5.5 p-6.5">
              <div className="">
                <label className="mb-2.5 block text-black dark:text-white">
                  Meta Title
                </label>
                <input
                  type="text"
                  name="metaTitle"
                  value={category?.metaTitle}
                  placeholder="Enter Meta Title"
                  className="w-full rounded border-[1.5px] border-primary bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="">
                <label className="mb-2.5 block text-black dark:text-white">
                  Keyword
                </label>
                <input
                  type="text"
                  name="keyword"
                  value={category?.keyword}
                  placeholder="Enter Keyword"
                  className="w-full rounded border-[1.5px] border-primary bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="">
                <label className="mb-2.5 block text-black dark:text-white">
                  Meta Description
                </label>
                <textarea
                  rows={4}
                  name="metaDescription"
                  value={category?.metaDescription}
                  placeholder="Enter Meta Description"
                  className="w-full rounded border-[1.5px] border-primary bg-transparent py-1 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryDetails;
