import { ErrorMessage, Field, Form, Formik } from 'formik';
import Breadcrumb from '../../components/Breadcrumb';
import OptionService from '../../services/OptionService';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useParams, Params, Link } from 'react-router-dom';
import AddOptionMediaImage from '../../components/AddOptionMediaImage';
import UpdateOptionMediaImage from '../../components/UpdateOptionMediaImage';

interface Media{
    mediaId:string;
    mediaUrl:string;
    altTag:string;
}

interface OptionInterface{
    optionId?:any|null;
    name:string;
    price:string;
    productName:string;
    mediaUrls:Media[];
}

const OptionMediaImages: React.FC<{}> = () => {
  const { optionId } = useParams<Params>(); 
  const [option, setOption] = React.useState<OptionInterface>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (optionId != undefined) {
          const response: AxiosResponse<OptionInterface> =
            await OptionService.getOption(optionId);
          const responseData: OptionInterface = response.data;
          setOption(responseData);
        } 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const [addImage, setAddImage] = useState(false);
  const [updateMediaImage, setUpdateMediaImage] = useState('');

  const deleteMediaImage = (optionId: any, mediaId: any) => {
   const response= OptionService.removeOptionMedia(optionId, mediaId).then(response => {
    setOption(response.data);      
  });
  
  };

  return (
    <>
      <Breadcrumb pageName="Option Gallary Images" />
      <div className='px-5 py-4 bg-white '>
        <div className='xl:flex xl:space-x-5'>
      <h3 className="font-medium text-black text-xl dark:text-white mb-5"><b>Option:</b> {option?.name}</h3>
      <h3 className="font-medium text-black text-xl dark:text-white mb-5"><b>Product name:</b> {option?.productName}</h3>

      </div>
            <div className="flex flex-col  justify-center ">
            <div className="xl:flex  justify-center space-x-5  w-full" >

            {option?.mediaUrls.map((media, index) => (
                <div key={index}>
                <div className="flex mt-5 p-1 backdrop-blur w-50  " >
                  <img
                    src={media?.mediaUrl}
                    alt={media.altTag ? media.altTag : option.name}
                    className=" "
                  />
                </div>
                <div className='flex justify-between'>
                <div className="flex items-center justify-center w-40 h-8 xl:mt-8 mt-12 cursor-pointer gap-2 rounded bg-primary py-1.5 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4">
                  <button
                    type="submit"
                    onClick={() => setUpdateMediaImage(media.mediaId)}
                    className="flex space-x-1"
                  >
                    <svg
                      className="fill-current mt-0.5"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM2.625 4.66663H4.75C4.94439 4.66663 5.12614 4.76353 5.23464 4.9258L6.22814 6.41663H10.7719L11.7654 4.9258C11.8739 4.76353 12.0556 4.66663 12.25 4.66663H13.0833C13.2487 4.66663 13.4066 4.72875 13.5192 4.83748C13.6318 4.94621 13.6903 5.09291 13.6833 5.2433L12.8276 11.0037C12.7555 11.4467 12.3443 11.75 11.8958 11.75H2.10416C1.65568 11.75 1.24446 11.4467 1.17239 11.0037L0.316686 5.2433C0.309722 5.09291 0.368198 4.94621 0.480796 4.83748C0.593395 4.72875 0.75128 4.66663 0.916664 4.66663H2.625ZM11.0833 12.8333H2.91667V11.9166H11.0833V12.8333Z"
                        fill="#ffffff"
                      />
                    </svg>
                    <span>Update Image </span>
                  </button>
                  {updateMediaImage && (
                    <UpdateOptionMediaImage
                      onCancel={() => setUpdateMediaImage('')}
                      mediaId={updateMediaImage}
                      optionId={option.optionId}
                    />
                  )}



                </div>
                <button
                          className="hover:text-primary mt-8 font-bold text-xl"
                          onClick={() => deleteMediaImage(option.optionId, media.mediaId)}
                        >
                          <svg
                            className="fill-danger "
                            width="25"
                            height="25"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                              fill=""
                            />
                            <path
                              d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                              fill=""
                            />
                            <path
                              d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                              fill=""
                            />
                            <path
                              d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                              fill=""
                            />
                          </svg>
                        </button>
                        </div>
              </div>
            ))}
            </div>

            <div className='flex  justify-center'>
            {option?.mediaUrls?.length == undefined ||
              (option?.mediaUrls?.length < 5 && (
                <div className="flex  justify-center w-80 h-8 xl:mt-10  mt-12 cursor-pointer gap-2 rounded bg-primary py-1.5 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4">
                <button
                  type="submit"
                  onClick={() => setAddImage(true)}
                  className="flex space-x-1 "
                >
                  <svg
                    className="fill-current mt-0.5 "
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM2.625 4.66663H4.75C4.94439 4.66663 5.12614 4.76353 5.23464 4.9258L6.22814 6.41663H10.7719L11.7654 4.9258C11.8739 4.76353 12.0556 4.66663 12.25 4.66663H13.0833C13.2487 4.66663 13.4066 4.72875 13.5192 4.83748C13.6318 4.94621 13.6903 5.09291 13.6833 5.2433L12.8276 11.0037C12.7555 11.4467 12.3443 11.75 11.8958 11.75H2.10416C1.65568 11.75 1.24446 11.4467 1.17239 11.0037L0.316686 5.2433C0.309722 5.09291 0.368198 4.94621 0.480796 4.83748C0.593395 4.72875 0.75128 4.66663 0.916664 4.66663H2.625ZM11.0833 12.8333H2.91667V11.9166H11.0833V12.8333Z"
                      fill="#ffffff"
                    />
                  </svg>
                  <span className="hidden xl:inline-block">
                    Add Gallary image
                  </span>
                  <span className="xl:hidden ">Add Image</span>
                </button>
                {addImage && (
                  <AddOptionMediaImage
                    onCancel={() => setAddImage(false)}
                    optionId={optionId}
                  />
                )}
                </div>
              ))}
          </div>
          </div>
          </div>
            
    </>
  );
};

export default OptionMediaImages;
